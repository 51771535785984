import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "shopify", "overlay" ]

  connect () {
  }

  selectIntegration (e) {
    console.log(e.currentTarget.checked);
    const selectedOverlay = e.currentTarget.nextElementSibling.querySelector('.selected-overlay');
    const overlays = this.overlayTarget.getElementsByClassName("selected-overlay");
    overlays.forEach(overlay => overlay.classList.add("d-none"));
    selectedOverlay.classList.remove('d-none');
  }
}